import React from "react"
import SEO from "../components/seo"


const PassionPage = () => (
  <div  id="app_center" class="container_center">
    <SEO title="Nos passions" description="Ce qui fait que nous aimons notre métier et vos projets."/>

    <h1>Ce qui nous passionne</h1>

    <div class="container_line">En terme de projet:</div>

    <div class="container_line">
    Les balbutiements d'un projet impliquant une exploration conceptuelle approfondie, avec de nouveaux défis techniques et de conception. Résoudre de vrais problèmes commerciaux ou sociaux. Permettre à nos clients de passer plus de temps sur leur coeur de métier en leur simplifiant la vie.
    </div>

    <div class="container_line">
    En terme technologique:
    </div>
    <div class="container_line">
    ReactJS, .Net, BabylonJS, Scss, NPM, BotFramework, NoSQL RavenDB, GraphQL, Apollo, GATSBY, WordPress,...
    </div>
    <div class="container_line">
    Liste non exhaustive...
    </div>
  </div>
)

export default PassionPage
